import styled from 'styled-components'

export const Container = styled.div`
  color: black;
  display: flex;

  @media (min-width: 600px) {
    padding-right: 100px;
  }

  @media (max-width: 1280px) {
    max-width: 550px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    width: 90%;
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`

export const StoreButtons = styled.div`
  display: flex;

  @media (max-width: 600px) {
    display: block;

    & a {
      display: block;
    }
  }
`

export const LinkList = styled.ul`
  margin-right: 30px;
  margin-bottom: 40px;
  padding: 0;

  @media (max-width: 1280px) {
    margin: 0 40px 20px 0;
  }

  @media only screen and (max-width: 600px) {
    width: 50%;
    margin-right: 0;
  }
`

export const LandingList = styled(LinkList)`
  @media only screen and (max-width: 600px) {
    width: 100%;
    flex-shrink: 0;
    padding: 0;
    margin-right: 0;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
  }
`

export const LinkItem = styled.li`
  list-style: none;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  letter-spacing: 0.01em;
  color: #000000;

  @media (max-width: 1280px) {
    white-space: nowrap;
  }

  @media only screen and (max-width: 600px) {
    white-space: nowrap;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
  }

  & a,
  & button {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: #000000;
    text-decoration: none;
  }

  & button {
    border: 0;
    margin: 0;
    padding: 0;
    background: transparent;
  }
`

export const LandingItem = styled(LinkItem)`
  @media only screen and (max-width: 600px) {
    width: 50%;
    box-sizing: border-box;
  }
`

export const More = styled.div`
  position: relative;
  cursor: pointer;
  width: fit-content;
  & > a {
    font-family: Helvetica Neue;
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: 0.01em;
    width: 100%;
    display: block;

    &:hover {
      color: #ff5e6d;
    }
  }
  &:target {
    & > a {
      color: #ff5e6d;
    }
    & .more-popup {
      transform: none;
    }
  }
`

export const ClosePopup = styled.a`
  position: fixed !important;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 0 !important;
  cursor: default;
`

export const MorePopup = styled.div`
  transform: scale(0);

  width: 160px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px #0000001a;
  position: absolute;
  top: -50%;
  left: 120%;

  @media only screen and (max-width: 600px) {
    left: 0;
    top: 100%;
  }

  & a {
    display: block;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #000;
    padding: 0 30px 0 14px;
    position: relative;
    z-index: 100;
    border-bottom: 1px solid #f5f5f5;

    &:hover {
      color: #ff5e6d;
    }

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }
`
