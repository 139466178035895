import Link from 'next/link'
import { ClosePopup, Container, LandingItem, LandingList, LinkItem, LinkList, More, MorePopup, StoreButtons } from './FooterLinks.styles'

import appStoreButtonImage from '../../assets/landing/appStoreButton.svg'
import googlePlayButtonImage from '../../assets/landing/googlePlayButton.svg'

const FooterLinks = () => {
  return (
    <>
      <Container>
        <LandingList>
          <LandingItem>
            <a target="_blank" href={'/bathroom-design-app'}>
              Bathroom Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/kitchen-design-app'}>
              Kitchen Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/bedroom-design-app'}>
              Bedroom Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/room-layout-planner-app'}>
              Room Layout Planner
            </a>
          </LandingItem>
        </LandingList>
        <LandingList>
          <LandingItem>
            <a target="_blank" href={'/office-design-app'}>
              Office Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/living-room-design-app'}>
              Living Room Design
            </a>
          </LandingItem>
          <LandingItem>
            <a target="_blank" href={'/dining-room-design-app'}>
              Dining Room Design
            </a>
          </LandingItem>
          <More id="viewMore">
            <a href="#viewMore">View More {'>'}</a>
            <MorePopup className="more-popup">
              <ClosePopup href="#" />
              <a target="_blank" href={'/outdoor-design-app'}>
                Outdoor Design
              </a>
              <a target="_blank" href={'/backyard-design-app'}>
                Backyard Design
              </a>
              <a target="_blank" href={'/garage-design-app'}>
                Garage Design
              </a>
              <a target="_blank" href={'/patio-design-app'}>
                Patio Design
              </a>
            </MorePopup>
          </More>
        </LandingList>
        <LinkList>
          <LinkItem>
            <Link href={'/team'}>Team</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/careers'}>Careers</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/about'}>About</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/blog'}>Blog</Link>
          </LinkItem>
        </LinkList>

        <LinkList>
          <LinkItem>
            <Link href={'/help-center'}>Help Center</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/terms'}>Terms of service</Link>
          </LinkItem>
          <LinkItem>
            <Link href={'/policy'}>Privacy Policy</Link>
          </LinkItem>
          <LinkItem>
            <StoreButtons>
              <a style={{ marginRight: 10 }} href="https://apps.apple.com/us/app/decor-matters-design-shop/id1168709467">
                <img src={appStoreButtonImage} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.decormatters.prod">
                <img src={googlePlayButtonImage} alt="" />
              </a>
            </StoreButtons>
          </LinkItem>
        </LinkList>
      </Container>
    </>
  )
}

export default FooterLinks
